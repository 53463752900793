// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  ws: 'https://hybrido.ayudatelta.com/api/',
  wsREP: 'https://repws2.ayudatelta.com/api/',
  baseBackend: 'https://hybrido.ayudatelta.com/',
  app: 'https://solicitud.ayudatelta.com/#/',
  ws_php: 'https://app.ayudatelta.com/',
  ws_webSocket: 'https://appv2wsmono.ayudatelta.com/',
  WS_GraphQL: 'https://solicitudv2wsmono.ayudatelta.com/graphql',
  WS_Admin: 'https://appv2wsmono.ayudatelta.com/api/',
  FeatureFlagURL: 'https://d23sb1amsbr6m.cloudfront.net/flags.json',

};
